import React, { useState } from "react";
import {
  Container,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import { fetchVenuesByLocation } from "../components/../helpers/onCallFunctions.ts";
import { LocationSearchBar } from "../components/SearchBars/LocationSearchBar.tsx";
import { WhatsOnButton } from "../components/WhatsOnButton/WhatsOnButton.tsx";
import { StandardTextBlock } from "../components/StandardTextBlock/StandardTextBlock.tsx";
import { LoadingElement } from "../components/LoadingElement/LoadingElement.tsx";
import { Venue } from "../types/types.ts";

export const VenueSelector: React.FC = () => {
  const [selectedVenues, setSelectedVenues] = useState<string[]>([]);
  const [venuesNearLocation, setVenuesNearLocation] = useState<Venue[]>([]);
  const [hasUserSelectedLocation, setHasUserSelectedLocation] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();

  const handleLocationSelect = async (latitude: number, longitude: number) => {
    try {
      setLoading(true);
      const fetchedVenues = await fetchVenuesByLocation({
        latitude,
        longitude,
      });
      setVenuesNearLocation(fetchedVenues);
      setSelectedVenues([]);
      setHasUserSelectedLocation(true);
      setLoading(false);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleLocationClear = async () => {
    setSelectedVenues([]);
    setVenuesNearLocation([]);
    setHasUserSelectedLocation(false);
  };

  const handleVenueCheckboxClick = (
    clickedVenue: string,
    previouslySelectedVenues: string[]
  ) => {
    if (previouslySelectedVenues.includes(clickedVenue)) {
      setSelectedVenues(
        selectedVenues.filter((venue) => venue !== clickedVenue)
      );
    } else {
      setSelectedVenues([...previouslySelectedVenues, clickedVenue]);
    }
  };

  return (
    <Container sx={{ width: { xs: "100%", md: "65%" } }}>
      <Box
        marginTop={{ xs: 3, md: 5 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ marginBottom: 2, fontSize: { xs: "small", md: "medium" } }}
        >
          Discover every movie being shown in the UK, all in one place with
          CinemaGuide.
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ marginBottom: 2, fontSize: { xs: "small", md: "medium" } }}
        >
          Enter your location to explore what's showing near you and plan your
          next cinema trip effortlessly:
        </Typography>
      </Box>
      <LocationSearchBar
        setLocation={handleLocationSelect}
        clearLocation={handleLocationClear}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <LoadingElement></LoadingElement>
        </Box>
      ) : (
        venuesNearLocation.length > 0 && (
          <Box sx={{ ml: { xs: 0, md: 5 }, mr: { xs: 0, md: 5 } }}>
            <StandardTextBlock
              text="Select the venues you're interested in: "
              margin={2}
              padding={1}
            />
            <FormGroup>
              {venuesNearLocation.map(
                ({ venue_name, distanceBetweenUserAndVenue }) => (
                  <FormControlLabel
                    onChange={() =>
                      handleVenueCheckboxClick(venue_name, selectedVenues)
                    }
                    sx={{ marginBottom: 1, marginRight: 0 }}
                    label={
                      <Typography
                        color={
                          selectedVenues.includes(venue_name)
                            ? "text.primary"
                            : "text.secondary"
                        }
                        sx={{
                          width: "100%",
                          fontSize: { xs: "small", md: "medium" },
                        }}
                      >
                        {venue_name} | {distanceBetweenUserAndVenue.toFixed(1)}
                        km away
                      </Typography>
                    }
                    key={venue_name}
                    control={
                      <Checkbox
                        checked={selectedVenues.includes(venue_name)}
                        size="large"
                      />
                    }
                  />
                )
              )}
            </FormGroup>
          </Box>
        )
      )}
      {!loading && hasUserSelectedLocation && venuesNearLocation.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <Typography
              variant="body1"
              textAlign="left"
              sx={{ marginBottom: 2, fontSize: { xs: "small", md: "medium" } }}
            >
              Can't see your favourite Cinema?&nbsp;
            </Typography>
            <Typography
              onClick={() => {
                window.location.href = "request-a-venue";
              }}
              variant="body1"
              sx={{
                marginBottom: 2,
                fontSize: {
                  xs: "small",
                  md: "medium",
                  color: theme.palette.primary.main,
                  textDecoration: "underline",
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              }}
            >
              Request it here
            </Typography>
          </Box>
          <WhatsOnButton
            venues={selectedVenues}
            isButtonDisabled={selectedVenues.length < 1}
          />
        </>
      )}
      {!loading &&
        hasUserSelectedLocation &&
        venuesNearLocation.length === 0 && (
          <StandardTextBlock
            text="There are no venues to display. Try another location."
            margin={2}
            padding={2}
          />
        )}
    </Container>
  );
};
