import React from "react";
import {
  TextField,
  Autocomplete,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import "@react-google-maps/api";
import PlaceIcon from "@mui/icons-material/Place";

interface SearchBarProps {
  setLocation: (latitude: number, longitude: number) => void;
  clearLocation: () => void;
}

export const LocationSearchBar: React.FC<SearchBarProps> = ({
  setLocation,
  clearLocation,
}) => {
  const theme = useTheme();

  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    callbackName: "initMaps",
    requestOptions: {
      componentRestrictions: { country: "gb" },
    },
  });

  const handleInputChange = (
    event: React.SyntheticEvent,
    userInput: string
  ) => {
    setValue(userInput);
  };

  const handleSelect = async (
    event: React.SyntheticEvent,
    address: string | null
  ) => {
    try {
      if (address) {
        setValue(address, false);
        clearSuggestions();
        const results = await getGeocode({ address });
        const { lat, lng } = getLatLng(results[0]);
        setLocation(lat, lng);
      } else {
        clearLocation();
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return (
    <Autocomplete
      freeSolo
      blurOnSelect
      options={status === "OK" ? data.map((place) => place.description) : []}
      onInputChange={handleInputChange}
      onChange={handleSelect}
      disabled={!ready}
      sx={{
        marginTop: { xs: 1, md: 2 },
        marginBottom: { xs: 1, md: 2 },
        ml: { xs: 0, md: 5 },
        mr: { xs: 0, md: 5 },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter Location"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            type: "text",
            
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.palette.primary.main, // Optional: If you want the border to be green as well
              },
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemIcon sx={{ marginRight: 1, minWidth: "auto" }}>
            <PlaceIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={option} />
        </ListItem>
      )}
    />
  );
};
