import * as React from "react";
import { FilmScreening } from "../../types/types.ts";
import { Grid } from "@mui/material";
import { IndividualScreeningInfo } from "../IndividualScreeningInfo/IndividualScreeningInfo.tsx";

interface ScreeningsForOneDateProps {
  screenings: FilmScreening[];
  filmTitle: string;
}

export const ScreeningsForOneDate: React.FC<ScreeningsForOneDateProps> = ({
  screenings,
  filmTitle,
}) => {
  return (
    <Grid
      sx={{ marginTop: 1, marginLeft: 0, marginRight: 0 }}
      container
      gap={{ xs: 1, md: 2 }}
    >
      {screenings.map(({ venue_name, link, time }, index) => (
        <Grid
          item
          xs={5.8}
          md={3.5}
          key={`${filmTitle}-${venue_name}-${index}`}
        >
          <IndividualScreeningInfo
            venue_name={venue_name}
            link={link}
            time={time}
            filmTitle={filmTitle}
          />
        </Grid>
      ))}
    </Grid>
  );
};
